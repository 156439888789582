















import { defineComponent } from '@vue/composition-api'
import { LoaderContent } from '@/inc/types/components'

export default defineComponent({
  name: 'loader',
  components: {},
  props: {
    content: {
      type: Object as () => LoaderContent,
    },
  },
})

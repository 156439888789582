var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"my-password wrapper m-l"},[(_vm.i18n && _vm.i18n.finalise)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xxxs-20 col-s-14"},[(_vm.i18n.finalise.title || _vm.i18n.finalisePassword.title)?_c('h1',{staticClass:"mb-m"},[_vm._v(" "+_vm._s(_vm.isReset ? _vm.i18n.finalisePassword.title : _vm.i18n.finalise.title)+" ")]):_vm._e(),(_vm.invalid)?_c('div',{on:{"~click":function($event){return _vm.resendMail($event)}}},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.i18n.resetPassword.subtitle)}}),(_vm.isLoadingMessage)?_c('loader',{attrs:{"content":{
            htmltext: _vm.i18n.resetPassword.messageLoading,
          }}}):_vm._e(),(_vm.message)?_c('feedback-message',{staticClass:"col-xxxs-20 mt-s",attrs:{"content":{
            type: _vm.hasError ? 'error' : 'success',
            htmltext: _vm.message,
          }}}):_vm._e()],1):_c('div',[(_vm.i18n.finalise.subtitle || _vm.i18n.finalisePassword.subtitle)?_c('p',{staticClass:"h3 password-subtitle"},[_vm._v(" "+_vm._s(_vm.isReset ? _vm.i18n.finalisePassword.subtitle : _vm.smsValidate ? _vm.i18n.finalise.subtitleWithPhone : _vm.i18n.finalise.subtitle)+" ")]):_vm._e(),_c('p',{staticClass:"password-summary"},[_vm._v(" "+_vm._s(_vm.i18n.finalise.subtitleSummary)+" ")]),_c('p',{staticClass:"fatline mb-m"},[_vm._v("Création de mot de passe")]),(_vm.resetConfirmed)?_c('div',[_c('feedback-message',{staticClass:"mb-s",attrs:{"content":{
              type: 'success',
              htmltext: _vm.i18n.finalisePassword.confirm,
            }}}),_c('g-action',{key:"out",attrs:{"content":{
              tag: 'button',
              label: _vm.mYi18n.login.button.label,
              icon: 'arrow',
            }},on:{"on-click":_vm.signIn}})],1):_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[_c('message',{attrs:{"content":{
              label: _vm.i18n.finalise.fields.label,
              htmltext: _vm.i18n.finalise.fields.rule,
            },"severity":"success"}}),_c('v-input',{staticClass:"mb-s",attrs:{"label":_vm.i18n.finalise.fields.password,"required":true,"type":'password',"id":_vm.i18n.finalise.fields.password,"hidePassword":true},on:{"blur":_vm.checkPassword},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('v-input',{staticClass:"mb-s",attrs:{"label":_vm.i18n.finalise.fields.passwordConfirm,"required":true,"type":'password',"id":_vm.i18n.finalise.fields.passwordConfirm,"hidePassword":true},on:{"input":_vm.checkMatch},model:{value:(_vm.passwordConfirmation),callback:function ($$v) {_vm.passwordConfirmation=$$v},expression:"passwordConfirmation"}}),(_vm.errorMessage)?_c('feedback-message',{staticClass:"mb-s",attrs:{"content":{ type: 'error', htmltext: _vm.errorMessage }}}):_vm._e(),(_vm.hasValue && !_vm.isPasswordValid && _vm.password)?_c('feedback-message',{staticClass:"mb-s",attrs:{"content":{ type: 'error', htmltext: _vm.i18n.finalise.fields.rule }}}):_vm._e(),(
              !_vm.isPasswordMatching && _vm.isPasswordValid && _vm.passwordConfirmation
            )?_c('feedback-message',{staticClass:"mb-s",attrs:{"content":{
              type: 'error',
              htmltext: _vm.i18n.finalise.fields.donotmatch,
            }}}):_vm._e(),_c('div',{staticClass:"nav"},[(_vm.recaptchaKey && !_vm.isReset)?_c('vue-recaptcha',{staticClass:"mb-s nav__message",attrs:{"sitekey":_vm.recaptchaKey,"loadRecaptchaScript":true,"language":_vm.chrome.currentLang !== null ? _vm.chrome.currentLang : 'en'},on:{"verify":_vm.setRecaptcha}}):_vm._e(),_c('g-action',{staticClass:"submit",class:{ 'is-loading': _vm.isLoading },attrs:{"content":{
                tag: 'button',
                label: _vm.i18n.finalise.button.label,
                icon: 'arrow',
              },"disabled":!_vm.isReady,"type":"submit"}})],1)],1)])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }
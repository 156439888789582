





















































































































































import {
  defineComponent,
  onBeforeMount,
  onMounted,
  ref,
  computed,
  toRef,
  watch,
} from '@vue/composition-api'
import { useState, useGetters } from '@u3u/vue-hooks'
import axios, { AxiosResponse } from 'axios'
import VueRecaptcha from 'vue-recaptcha'

import userAccountRequest from '@/composables/userAccountRequest'
import { ERROR_CODES } from '@/composables/const'
import { getApiUrl, passwordRegex } from '@/inc/app.config'
import { getCallbackUrl, fetchLive } from '@/inc/utils'
import * as sso from '@/composables/sso'

import FeedbackMessage from '@/components/ui/FeedbackMessage.vue'
import Loader from '@/components/ui/Loader.vue'
import VInput from '@/components/form/VInput.vue'
import Message from '@/components/ui/Message.vue'
import jwtDecode from 'jwt-decode'

export default defineComponent({
  name: 'my-password',
  components: {
    FeedbackMessage,
    Loader,
    VInput,
    VueRecaptcha,
    Message,
  },
  props: {
    user: Object,
  },

  setup(props, ctx) {
    /* eslint-disable */
    const i18n = ref<Record<string, any>>()
    const content = ref<Record<string, any>>()
    /* eslint-enable */

    const { chrome } = useState(['chrome'])
    const { $i18n, $route, $router } = ctx.root

    const errorMessage = ref('')
    const hasValue = ref(false)
    const isLoading = ref(false)
    const isLoadingMessage = ref(false)
    const isPasswordMatching = ref(false)
    const isPasswordValid = ref(false)
    const isReset = ref(false)
    const password = ref('')
    const passwordConfirmation = ref('')
    const recaptcha = ref('')
    const recaptchaKey = ref(chrome.value.data?.recaptcha)
    const resetConfirmed = ref(false)
    const invalid = ref(false)
    const inputType = ref(false)
    const inputTypeCheck = ref(false)
    const uid = ref('')
    const email = ref('')
    const hasError = ref(false)
    const message = ref('')
    const { $logger } = ctx.root

    const showPassword = () => {
      inputType.value = !inputType.value
    }
    const showPasswordCheck = () => {
      inputTypeCheck.value = !inputTypeCheck.value
    }
    let smsValidate = false

    if ($route.query.SmsValidate) {
      smsValidate = true
    }

    watch(toRef(chrome.value, 'data'), newValue => {
      recaptchaKey.value = newValue.recaptcha
    })

    onMounted(() => {
      const token = $route.query.Token
      if (token !== '') {
        let tokenDecode = ref({})
        tokenDecode = jwtDecode(token as any)
        uid.value = tokenDecode?.['cn']
        email.value = tokenDecode?.['email']
        const expirationTimestamp = Math.floor(
          tokenDecode?.['expirationTimestamp']
        )

        if (expirationTimestamp < Math.floor(Date.now() / 1000)) {
          invalid.value = true
        }
      }
    })

    onBeforeMount(async () => {
      if (!$route.query.Token) {
        $router.push({
          name: 'MySignin',
          params: { lang: $i18n.locale },
        })
      }
      isReset.value = $route.name === 'MyResetPassword'
      const { route: createRoute } = $router.resolve('/create')
      const res: AxiosResponse = await fetchLive(createRoute)

      content.value = res.data.content || {}
      i18n.value = res.data.i18n || {}
    })

    const signIn = () => {
      let cb: string | undefined
      const { from } = $route.query

      if (from === 'ppp') {
        cb = '/ppp/success/'
      }
      sso.authorize(cb)
    }

    const isReady = computed(() => {
      if (isReset.value) {
        return isPasswordValid.value && isPasswordMatching.value
      }

      return (
        isPasswordValid.value && isPasswordMatching.value && recaptcha.value
      )
    })

    const checkPassword = () => {
      hasValue.value = true
      isPasswordValid.value = passwordRegex.test(password.value)
    }

    const checkMatch = () => {
      isPasswordMatching.value = password.value === passwordConfirmation.value
    }

    const onSubmit = async () => {
      const path = isReset.value ? 'users/reset' : 'users/activate'
      const params = {
        Token: $route.query.Token,
      }
      isLoading.value = true
      resetConfirmed.value = false
      errorMessage.value = ''

      if (!isReset.value) {
        params['g-recaptcha-response'] = recaptcha.value
      }

      const { error } = await userAccountRequest(
        path,
        'post',
        { Password: password.value },
        { params }
      )

      if (error) {
        isLoading.value = false
        const { ErrorCode } = error?.response?.data?.originalBody
        const finalisePasswordTrad = i18n?.value?.finalisePassword
        let errorMessageTrad
        switch (ErrorCode) {
          case ERROR_CODES.TOKEN_REVOKED:
            errorMessageTrad = finalisePasswordTrad.tokenRevoked
            break
          case ERROR_CODES.TOKEN_EXPIRED:
            errorMessageTrad = finalisePasswordTrad.tokenExpired
            break
          default:
            errorMessageTrad = finalisePasswordTrad.defaultError
            break
        }
        errorMessage.value = `${errorMessageTrad} ${finalisePasswordTrad.redo}`

        return
      }

      let name = 'MySuccess'

      if ($route.name === 'MyPppPassword') {
        name = 'MyPppSuccess'
      }

      isLoading.value = false

      if (isReset.value) {
        resetConfirmed.value = true
      } else {
        $router.push({ name })
      }
    }

    const setRecaptcha = res => {
      recaptcha.value = res
    }

    const resendMail = async () => {
      isLoadingMessage.value = true
      hasError.value = false
      message.value = ''

      const callback = getCallbackUrl('/reset/password/')
      try {
        const res = await axios.post(
          `${getApiUrl()}/users/reset_password/sendmail?Callback=${callback}&Email=${
            email.value
          }`,
          {
            SessionId: uid.value,
          }
        )

        if (res.status === 200) {
          message.value = i18n?.value?.resetPassword.confirm
        } else {
          hasError.value = true
          res.data.message.value = message
        }
      } catch (error) {
        $logger.error('ResetPasswordError 😱', error)
        hasError.value = true
        if (
          error.response.data.originalBody.ErrorCode === 'UNKNOWN_USER_MAIL'
        ) {
          message.value = i18n?.value?.resetPassword.errorUnknownUserMail
        } else {
          message.value = error.response.data.message
        }
      }

      isLoadingMessage.value = false
    }

    return {
      recaptcha,
      mYi18n: useState('my', ['i18n'])?.i18n,
      checkMatch,
      checkPassword,
      content,
      errorMessage,
      hasValue,
      i18n,
      isLoading,
      isLoadingMessage,
      isPasswordMatching,
      isPasswordValid,
      isReady,
      isReset,
      onSubmit,
      password,
      passwordConfirmation,
      recaptchaKey,
      resetConfirmed,
      invalid,
      setRecaptcha,
      signIn,
      chrome,
      smsValidate,
      inputType,
      inputTypeCheck,
      showPassword,
      showPasswordCheck,
      resendMail,
      uid,
      email,
      hasError,
      message,
    }
  },
})
